import * as React from 'react';
import { useState } from 'react';
import ImportIcon from '@mui/icons-material/Sync';
import { Button, useDataProvider, useListContext, useResourceContext } from 'react-admin';
import dataProvider from '../dataProvider';

const SyncAllButton = props => {
    const listContext = useListContext();
    const [loading, setLoading] = useState(false);

    const handleSyncAll = async () => {
        setLoading(true);
        try {
            for (let i = 0; i < listContext.data.length; i++) {
                console.log(listContext.data[i])
                await dataProvider.customs.syncMatch(listContext.data[i].id)
            }
            window.location.reload()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button
                onClick={handleSyncAll}
                label="Sync All"
                disabled={loading}
            >
                <ImportIcon />
            </Button>
        </div>
    );
};

export default SyncAllButton;
