import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    SearchInput,
    TextInput,
    Filter,
    TopToolbar,
    FilterButton,
    Pagination,
    ShowButton,
    useMediaQuery,
    SimpleList
} from 'react-admin';
import Icon from '@mui/icons-material/FormatListNumbered';

export const VolleyRankingIcon = Icon;

const filterArray = [
    // <SearchInput source="q" alwaysOn />,
    <TextInput source="competition_code_like" label="Competition Code" />,
    <TextInput source="competition_name_like" label="Competition Name" />,
    <TextInput source="team_like" label="Team" />
];

const FilterList = (props) => (
    <Filter {...props}>{filterArray}</Filter>
);

const ListActions = (props) => (
    <TopToolbar>
        <FilterButton filters={filterArray} />
    </TopToolbar>
);

export const VolleyRankingList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={50} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200]} />}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="competition_code" label="Competition Code" />
            <TextField source="competition_name" label="Competition Name" />
            <TextField source="poule" label="Poule" />
            <TextField source="team" label="Team" />
            <NumberField source="position" label="Position" />
            <NumberField source="points" label="Points" />
            <NumberField source="matches_played" label="Matches Played" />
            <NumberField source="wins" label="Wins" />
            <NumberField source="losses" label="Losses" />
            <TextField source="others['Set.P']" label="Sets Won" />
            <TextField source="others['Set.C']" label="Sets Lost" />
            <TextField source="others['Coeff.S']" label="Set Coefficient" />
            <TextField source="others['Pts.P']" label="Points Won" />
            <TextField source="others['Pts.C']" label="Points Lost" />
            <TextField source="others['Coeff.P']" label="Points Coefficient" />
            <DateField source="updatedAt" label="Last Updated" showTime={true} />
            <ShowButton />
        </Datagrid>
    </List>
);
