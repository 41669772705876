import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
    ImageField,
    TextInput,
    SearchInput,
    Filter,
    TopToolbar,
    FilterButton,
    Pagination,
    ShowButton,
    useMediaQuery,
    SimpleList
} from 'react-admin';
import Icon from '@mui/icons-material/Book';
// import SyncButton from '../components/SyncButton';
// import SyncAllButton from '../components/SyncAllButton';

export const MatchIcon = Icon;

const filterArray = [
    // <SearchInput source="q" alwaysOn />,
    <TextInput source="match_code" label="Match Code" />,
    // <TextInput source="competition_code_like" label="Competition Code" />,
    <TextInput source="competition_name_like" label="Competition Name" />,
    <TextInput source="EQA_nom_like" label="Team A" />,
    <TextInput source="EQB_nom_like" label="Team B" />,
    // <TextInput source="niveau_like" label="Niveau" />,
    <TextInput source="district_like" label="District" />,
    <TextInput source="codent" label="Codent" />,
    // <DateField source="match_date" label="Match Date" />
];

const FilterList = (props) => (
    <Filter {...props}>{filterArray}</Filter>
);

const ListActions = (props) => (
    <TopToolbar>
        {/* <SyncAllButton /> */}
        <FilterButton filters={filterArray} />
    </TopToolbar>
);

export const VolleyLeagueMatchList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={50} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200]} />}>
        <Datagrid>
            {/* <SyncButton /> */}
            <TextField source="id" label="ID" />
            <TextField source="match_code" label="Match Code" />
            {/* <TextField source="competition_code" label="Competition Code" /> */}
            <TextField source="competition_name" label="Competition Name" />
            <TextField source="codent" label="Codent" />
            {/* <TextField source="poule" label="Poule" />
            <TextField source="niveau" label="Niveau" /> */}
            <TextField source="district" label="District" />
            <TextField source="region" label="Région" />
            {/* <ImageField source="logo" label="Logo" /> */}
            <TextField source="jo" label="JO" />
            <TextField source="EQA_nom" label="Team A" />
            <TextField source="EQB_nom" label="Team B" />
            <FunctionField label="Score" render={(record) => `${record.Score}`} />
            <FunctionField label="Set" render={(record) => `${record.Set_equipe1}/${record.Set_equipe2}`} />
            <TextField source="Total" label="Total Points" />
            <TextField source="Salle" label="Venue" />
            <TextField source="Arb1" label="Referee 1" />
            <TextField source="Arb2" label="Referee 2" />
            <DateField source="match_date" label="Match Date" showTime={false} />
            <DateField source="updatedAt" label="Last Updated" showTime={true} />
            {/* <ShowButton /> */}
        </Datagrid>
    </List>
);